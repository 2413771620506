import './App.css';
import dogImage from "./dog1.jpg"


function App() {
  return (
    <div className="App">
      <header className="App-header">
      <p>This is my backup website - construction in progress!</p>
        <img src={"/photo.png"}></img>
        <h1>Simon Sprouse</h1>
        <ul>
            <li><a 
                href="http://example.com" 
                target="_blank"
                >
                About
                </a>
            </li>
            <li><a 
                href="/Resume.pdf" 
                target="_blank"
                >
                Resume
                </a>
            </li>
            <li><a 
                href="https://www.youtube.com/@SimonSprouse93"
                target="_blank"
                >
                YouTube
                </a>
            </li>
            <li><a 
                href="https://github.com/Simon-Sprouse" 
                target="_blank"
                >
                GitHub
                </a>
            </li>
            <li><a 
                href="https://www.linkedin.com/in/simon-sprouse" 
                target="_blank"
                >
                LinkedIn
                </a>
            </li>


        </ul>
      </header>
    </div>
  );
}

export default App;
